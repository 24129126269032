const tel = '087339982';

const telLabel = '3180 0658';

const generalTel = '31233338';

const generalTelLabel = '3123 3338';

const mobileAreaCode = '+855';

const gtmInit = (id: any) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${id}');`;

const antiFlickerStyleInit = () => `
  .async-hide {
    opacity: 0 !important;
  }
`;

const antiFlickerInit = (id: any) => `
        (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
      h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
      (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
      })(window,document.documentElement,'async-hide','dataLayer',4000,
      {'${id}':true});
`;

const getStaticPropsRevalidate = 1800; // TODO to enhancement later

const utmDataStorageName = 'D2C_UTM_TRACKING';

const utmDataStorageMaxAge = 90 * 24 * 60 * 60; // in second, 90 days be default

export default {
  tel,
  telLabel,
  generalTel,
  generalTelLabel,
  mobileAreaCode,
  gtmInit,
  antiFlickerInit,
  antiFlickerStyleInit,
  getStaticPropsRevalidate,
  utmDataStorageName,
  utmDataStorageMaxAge
};
